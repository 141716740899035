import { PortfolioMain, PortfolioRight, PortfolioText } from '../../StyledComponents/LandingStyled'
import React from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import { Col, Container, Row } from 'reactstrap'

export default function Portfolio() {
  return (
    <PortfolioMain>
      <Container>
        <AnimationOnScroll animateIn="animate__fadeInUp"
          animateOnce={true}>
          <div className="flex flex-col lg:flex-row justify-center items-center">
            <div className="w-[100%] lg:w-[60%]">
              {/* <PortfolioText>Take a look at how we elevated <br />
                businesses in different arenas!</PortfolioText> */}
                <PortfolioText className="lg:text-left text-center">We Design & Develop Software <br />Solutions for Diverse Industries</PortfolioText>
            </div>
            <div className="w-[100%] lg:w-[40%]">
              {/* <PortfolioRight className="pt-2">Yes, we do not use the term "customer". Become our partner. The overriding value of our team is partnership.</PortfolioRight> */}
              <PortfolioRight className="lg:text-left text-center">Tailored technology solutions to solve industry <br  /> challenges and drive business value.</PortfolioRight>
            </div>
          </div>
        </AnimationOnScroll>
      </Container>
    </PortfolioMain>
  )
}
